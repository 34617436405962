<template>

	<div>
		<div class="outer-wrapper"> 
			<div class="header" v-if="!contentLoading" :style="{'background-image': `url(${ headerImage })`}">
				<div class="gradient"></div>
				<div class="inner outer-wrapper">
					<div class="type">Country</div>
					<h1 class="heading4 name">{{ heading }}</h1>
					<div class="description">{{ description }}</div>
				</div>
			</div>

			<Loader :loading="contentLoading" :error="false" @refresh="init()"/>

			<div class="add-more" v-if="!contentLoading">
				<div class="add-more-container">
					<router-link class="item-wrapper has-text" :to="{ name: 'Flights Locations' }">
						<div class="item">
							<img src="@/assets/trip/flights.png" alt="plane"/>
						</div>
						<div class="item-text">
							Flights
						</div>
					</router-link>
					<router-link class="item-wrapper has-text" :to="{ name: 'Tours Search' }" @click.native="goToTours">
						<div class="item">
							<img src="@/assets/trip/tours.png" alt="flag"/>
						</div>
						<div class="item-text">
							Tours
						</div>
					</router-link>
				</div>
			</div>

			<div class="inner-wrapper" v-if="!contentLoading && !contentError && mainContent">
				<div class="main-content" :class="mainContent.length > 500 && !showMoreContent ? 'shorten' : ''">
					<div v-if="mainContent.length > 500" v-html="showMoreContent ? mainContent : mainContent.slice(0, 600)">
					</div>
					<div v-else v-html="mainContent">
					</div>
				</div>
				<div class="read-more" v-if="mainContent.length > 500">
					<span v-if="!showMoreContent" @click="showMoreContent = !showMoreContent">Read more</span>
					<span v-else @click="showMoreContent = !showMoreContent">Show less</span>
					<img class="arrow" src="@/assets/trip/arrow-up.png" :class="{ selected: showMoreContent }"/>

				</div>
			</div>

			<div class="youtube-outer-wrapper" v-if="youtubeID && !contentLoading && !contentError">
				<div class="youtube-inner-wrapper">
					<iframe width="100%" height="100%" :src="'https://www.youtube.com/embed/' + youtubeID" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
				</div>
			</div>

			<div class="inner-wrapper" v-if="!contentLoading && !contentError">
				<div class="main-content secondary" :class="secondaryContent.length > 500 && !showMoreSecondaryContent ? 'shorten' : ''">
					<div v-if="secondaryContent.length > 500" v-html="showMoreSecondaryContent ? secondaryContent : secondaryContent.slice(0, 600)">
					</div>
					<div v-else v-html="secondaryContent">
					</div>
				</div>
				<div class="read-more" v-if="secondaryContent.length > 500">
					<span v-if="!showMoreSecondaryContent" @click="showMoreSecondaryContent = !showMoreSecondaryContent">Read more</span>
					<span v-else @click="showMoreSecondaryContent = !showMoreSecondaryContent">Show less</span>
					<img class="arrow" src="@/assets/trip/arrow-up.png" :class="{ selected: showMoreSecondaryContent }"/>

				</div>
			</div>
			<div class="tour-wrapper" v-if="!contentLoading">
				<div class="inner-wrapper">
					<h2 class="heading7">Tours</h2>
				</div>

				<Loader :loading="toursLoading" :error="toursError" @refresh="getTours()"/>

				<div class="inner-wrapper" v-if="tours.length == 0 && !toursLoading && !toursError">
					We don't serve any tours in this country, unfortunately!
				</div>
				<swiper ref="tourSwiper" class="tours"  
					:options="swiperOptions"
					v-if="!toursLoading && !toursError"
				>
					<div class="prev" slot="button-prev"><img src="@/assets/arrows/circle-arrow.png" width="25px"/></div>
					<div class="next" slot="button-next"><img src="@/assets/arrows/circle-arrow.png" width="25px"/></div>
					<swiper-slide class="slide" v-for="tour in tours" :key="tour.id">
						<TourTallCard
							class="tour-card"

							:id="tour.id"
							:name="tour.name"
							:images="tour.images"
							:startLocation="tour.start_location"
							:endLocation="tour.end_location"
							:length="tour.length"
							:description="tour.description"
							:fromPrice="tour.advertised_price ? tour.advertised_price.find(obj => { return obj.currency == 'GBP' }) : false"
						/>
					</swiper-slide>
				</swiper>
				
			</div>
		

		</div><!-- end of outer wrapper -->

	</div>
	
</template>


<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import TopLeftIcon from '@/components/TopLeftIcon.vue'
import Menu from '@/components/Menu.vue'
import { searchService } from '@/services/searchService.js'
import TourTallCard from "@/components/tours/TourTallCard.vue";
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import Loader from '@/components/Loader.vue';
import contentService from '@/services/contentService.js'

export default {
    data () {
        return {
			countryCode: this.$route.params.country_code ? this.$route.params.country_code : null,
			
			headerImage: false,
			heading: null,
			description: null,
			mainContent: null,
			showMoreContent: false,
			youtubeID: null,
			secondaryContent: null,
			showMoreSecondaryContent: false,
			tours: [],

			contentLoading: false,
			toursLoading: false,
			contentError: false,
			toursError: false,
			error: false,

			swiperOptions: {
				slidesPerView: "auto",
				navigation: {
					nextEl: '.next',
					prevEl: '.prev',
				},
				loop: "true",
				grabCursor: "true",
				centeredSlides: "true",
				spaceBetween: 30,
				coverflowEffect: {
					rotate: 50,
					stretch: 0,
					depth: 100,
					modifier: 1,
					slideShadows: true,
				},
			},
        }
	},
	components: {
		TopLeftIcon,
		Menu,
		TourTallCard,
		Swiper,
		SwiperSlide,
		Loader,
	},
	directives: {
		swiper: directive
	},
    computed: {
		...mapState({
			toursSearch: state => state.toursSearch,
		}),
    },
    methods: {
		goToTours() {
			// Set the search values to the country code
			this.toursSearch.searchValues.keyword = {
				"code": this.countryCode,
				"name": this.heading,
				"type": "country",
			};
		},
		async getContent() {
			this.contentLoading = true;
			return contentService.getCountry(this.countryCode)
				.then((response) => {
					let fields = response.data.data.fields;
					this.headerImage = fields.header_image;
					this.heading = fields.heading;
					this.mainContent = fields.content1;
					this.youtubeID = fields.youtube_url;
					this.secondaryContent = fields.content2;

					this.contentLoading = false;
				},
				error => {
					this.heading = this.countryCode;
					this.contentError = error;
					this.contentLoading = false;
				});
		},
		async getTours() {
			this.toursLoading = true;

			return searchService.getCountryTours(this.countryCode)
				.then(response => {
					if(response) {
						this.tours = Object.values(response.data.data).slice(0, 5); // Convert to array
					}

					this.toursError = null;
					this.toursLoading = false;
				},
				error => {
					this.toursError = error;
					this.toursLoading = false;
				});
		},
		async init() {
			// Await both of these functions before continuing
			let contentPromise = this.getContent();
			let toursPromise = this.getTours();

			await Promise.all([contentPromise, toursPromise]);

			// console.log(this.headerImage);
			// If there is no header image from content, use a tours image as the header image instead
			if(!this.headerImage) {
				if(this.tours.length > 0) {
					let imagesPriority = [
						"LARGE_SQUARE",
						"BANNER",
						"BANNER_HIRES", // This is actually highest quality, but it's excessive for our purposes. Still better than regular 'BANNER' though.
						"BANNER_DESKTOP", // Highest Priority
					];
					let images = this.tours[this.tours.length -1].images;
					if(images && images.length > 0) {
						// Order images based on priority
						let orderedImages = images.sort((a, b) => {
							// Order based on imagesPriority
							let aIndex = imagesPriority.indexOf(a.type);
							let bIndex = imagesPriority.indexOf(b.type);
							if(aIndex > bIndex) {
								return -1;
							}
							if(aIndex < bIndex) {
								return 1;
							}
							return -1;
						});
						// Set the main image href to the highest priority image
						this.headerImage = orderedImages[0].image_href;
					}
					
				}
			}
		}
	},
	created() {
		this.$emit('update:topLayoutSettings', {
			left: {
				type: 'back',
				color: 'white',
				route: this.$route.query.redirect ? this.$route.query.redirect : { name: 'Home' },
			},
			right: {
				type: 'none',
			},
			overlayContent: true,
		});
	},
	async mounted () {
		this.init();
	},
};
</script>


<style scoped>
	.add-more {
		text-align: center;
		margin-bottom: 10px;
		margin-top: 10px;
	}
	.header {
		color: white;
		height: 250px;
		position: relative;
		background-size: cover;
		background-position: center;
	}
	.header .gradient {
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 50%;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #00272A 100%);
	}
	.header .inner {
		padding: 19px;
		position: absolute;
		bottom: 0;
		width: 100%;
		box-sizing: border-box;
		left: 50%;
		transform: translateX(-50%);
	}
	.header .inner .type {
		display: inline-block;
		padding: 0 5px;
		margin-bottom: 5px;
		height: 14px;
		background: #3D68BB;
		border-radius: 9px;

		font-weight: 700;
		font-size: 10px;
		line-height: 14px;
		color: white;
		text-align: center;
		text-transform: uppercase;
	}
	.header .inner .name {
		margin-bottom: 5px;
		line-height: 28px;
	}
	.header .inner .description {
		font-weight: 400;
		font-size: 14px;
	}

	.main-content {
		font-size: 14px;
	}
	.main-content.shorten {
		position: relative;
		margin-bottom: -10px; /* To replace the hidden text by the white gradient */
	}
	.main-content.shorten:after {
		content:"";
		position:absolute;
		bottom:0;
		left:0;
		height:70px;
		width:100%;
		background: linear-gradient(rgba(0,0,0,0) 0%, #fff 80%, #fff 100%);
	}
	.read-more {
		font-weight: 600;
		font-size: 12px;
		color: #000000;
		cursor: pointer;
		text-align: center;
		margin-top: 10px;
	}
	.read-more .arrow {
		margin: 0;
		width: 24px;
		transform: rotate(180deg);
		vertical-align: middle;
		margin-left: 5px;
	}
	.read-more .arrow.selected {
		transform: rotate(0deg);
	}

	.youtube-outer-wrapper {
		width: 100%;
		padding-top: 56%;
		position: relative;
		margin-top: 20px;
	}
	.youtube-inner-wrapper {
		position: absolute;
		right: 0; top: 0; left: 0; bottom: 0;
	}
	.main-content.secondary {
		margin-top: 20px;
	}

	/* Tours slider */
	.tours {
		margin-top: 20px;
		margin-bottom: 100px;
	}
	.tours .slide {
		width: 268px;
	}
	.tours .next,
	.tours .prev {
		position: absolute;
		z-index: 1;
		cursor: pointer;
		top: 50%;
		width: 25px;
		height: 25px;
	}
	.tours .next {
		right: 10px;
	}
	.tours .prev {
		left: 10px;
	}
	.tours .prev img {
		transform: rotate(180deg);
	}
	
</style>
